import React from "react"
import "../styles/index.scss"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import {
  container,
  fadeUp,
  fadeDown,
  fadeDownRL,
  fadeDownRR,
  scaleUpRR,
} from "../animations"

const pages = [
  {
    text: "Our Story",
    path: "/story",
    icon: "https://img.icons8.com/doodle/96/000000/information.png",
    local: true,
  },
  {
    text: "Photo Gallery",
    path: "/photos",
    local: true,
    icon: "https://img.icons8.com/doodle/96/000000/stack-of-photos--v1.png",
  },
  {
    text: "Shop Online",
    path: "https://birchandbenjamin.myshopify.com/",
    local: false,
    icon: "https://img.icons8.com/doodle/96/000000/shopping-basket-2--v1.png",
  },
  {
    text: "On Social Media",
    path: "/social",
    local: true,
    icon: "https://img.icons8.com/doodle/96/000000/email-sign.png",
  },
  {
    text: "Contact Us",
    path: "/contact",
    local: true,
    icon: "https://img.icons8.com/doodle/96/000000/comments.png",
  },
]

const IndexPage = () => (
  <Layout className="homepage">
    <SEO title="Home" />
    <motion.div variants={container} initial="out" animate="in">
      <motion.h1 variants={fadeDown}>Projects made easier.</motion.h1>

      <div className="brush-row">
        <motion.img
          variants={fadeDownRL}
          src="/artist_brush.png"
          alt="Artist Brush"
        />
        <motion.img
          variants={fadeDown}
          src="/paint_brush.png"
          alt="Paint Brush"
        />
        <motion.img
          variants={fadeDownRR}
          src="/roller_brush.png"
          alt="Roller Brush"
        />
      </div>

      <motion.h2 variants={fadeDown}>It begins with an idea...</motion.h2>
    </motion.div>

    <motion.nav
      variants={container}
      initial="out"
      animate="in"
      className="nav-row"
    >
      {pages.map(page => (
        <motion.div variants={fadeUp} whileHover={scaleUpRR} key={page.path}>
          {page.icon && (
            <a key={page.path + "img"} href={page.path}>
              <img src={page.icon} alt={page.text} className="nav-icon" />
            </a>
          )}
          {page.local ? (
            <Link key={page.path + "link"} to={page.path}>
              {page.text}
            </Link>
          ) : (
            <a key={page.path} href={page.path}>
              {page.text}
            </a>
          )}
        </motion.div>
      ))}
    </motion.nav>
  </Layout>
)

export default IndexPage
